import * as React from "react";
import { Link } from "gatsby";

import ArticleImage from "./article-image";

const ArticleCard = ({ article, isNewsArticle }) => (
  <article className="card" aria-label={article.title}>
    <p className="card-title">
      <Link to={`/articles/${article.slug}/`}>{article.title}</Link>
    </p>
    {isNewsArticle && (
      <>
        <p className="card-eyebrow">{article.publishedAt}</p>
        {article.lead && (
          <p className="card-description">
            {article.lead.lead.slice(0, 150)}
            {article.lead.lead.length > 150 && "…"}
          </p>
        )}
      </>
    )}

    <figure className="card-image">
      <ArticleImage articleImage={article.featuredImage} />
    </figure>
  </article>
);

export default ArticleCard;
