import * as React from "react";
import { Link } from "gatsby";

import SectionHeading from "./section-heading";
import ArticleCard from "./article-card";

const News = ({ articles, link, linkLabel, isMainSection }) => (
  <section className="section section-news" aria-labelledby="news-title">
    <div className="container">
      <SectionHeading
        id="news-title"
        title="Actualité économique du CSE"
        link={link}
        linkLabel={linkLabel}
        isMainSection={isMainSection}
      />
      <ul>
        {articles.map((article) => (
          <li key={article.id}>
            <ArticleCard article={article} isNewsArticle />
          </li>
        ))}
      </ul>
      {!isMainSection && <Link to="/actualites/">Toutes les actualités</Link>}
    </div>
  </section>
);

export default News;
