import * as React from "react";
import { Link } from "gatsby";

const HeadingLink = ({ link, children }) => {
  if (link.startsWith("https")) {
    return (
      <a href={link} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }

  return <Link to={link}>{children}</Link>;
};

const SectionHeading = ({ id, title, link, linkLabel, isMainSection }) => (
  <header className="section-heading">
    {isMainSection ? (
      <h1 id={id} className="h2">
        {title}
      </h1>
    ) : (
      <h2 id={id}>{title}</h2>
    )}
    {link && <HeadingLink link={link}>{linkLabel}</HeadingLink>}
  </header>
);

export default SectionHeading;
