import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import News from "../components/news";

const NewsPage = ({ data }) => {
  const news = data.articles.nodes.filter((article) => article.newsArticle);

  return (
    <Layout>
      <Seo title="Actualité économique du CSE" />
      <News articles={news} isMainSection />
    </Layout>
  );
};

export default NewsPage;

export const query = graphql`
  query {
    articles: allContentfulArticle(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        ...articleFields
        newsArticle
      }
    }
  }
`;
